export default [
  {
    path: '/userInfo',
    name: 'user-info',
    meta: {
      title: 'Convite Chá de Bebê para Editar - Informe Seus dados'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/forms/UserInfo.vue'),
  }
]

<template lang="pug">
  v-app
    //v-app-bar(app elevation="1" color="white")
      router-link(:to="{ name: 'landing' }")
        v-img(alt="Logo" class="shrink mr-2" contain :src="`${imgPath}/site/imgs/logo-ifraldas.png`" transition="scale-transition" width="110")
      v-spacer
      v-btn(style="text-transform: none !important;" depressed color="#F2FBFB" class="primary--text" @click="goToSite" small) Criar Lista de Presentes

    v-main
      router-view
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
  }),
  methods: {
    goToSite(){
      let href = 'https://www.ifraldas.com.br?utm_source=lp+convites&utm_medium=landing+page&utm_campaign=convites&utm_id=teste&utm_term=ifraldas'
      window.open(href, '_blank');
    }
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'iFraldas'
    },
  }
};
</script>
<style>
  @import './assets/css/invitations.css';

  .v-application {
    width: 100%;
    top: 0;
    overflow: auto;
  }

  .noScroll { overflow-x: hidden !important; }

  .mobileHidden{
    overflow: hidden;
    position: absolute;
    z-index: 1 !important;
  }
  .mobileStyle{ text-align: center; }
  .desktopStyle { height: 100% !important; }

</style>

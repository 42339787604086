import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(fas, far, fab)

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

const vuetify = new Vuetify({
  icons: {
    iconfont: 'faSvg'
  },
  theme: {
    dark: JSON.parse(localStorage.getItem('dark')),
    themes:
      {
        light: {
          primary: '#55CDC7',
          secondary: '#D2CDCA',
          accent: '#5D737E',
          success: '#4CAF50',
          info: '#2196F3',
          warning: colors.yellow.darken3,
          error: '#FD6A6E',
          appBar: '#EFF1F2',
          warningFont: colors.grey.darken2,
          buttons: '#FDA7A0',
          invitationTitle: '#4DC5C1',
          darkText: '#25313D'
        },
        dark: {
          primary: '#609C92',
          secondary: colors.grey.darken3,
          accent: '#FF4081',
          success: '#4CAF50',
          info: '#2196F3',
          warning: colors.yellow.darken2,
          error: '#FF5252',
          appBar: colors.grey.darken1,
          warningFont: colors.grey.darken2,
          buttons: '#FFA6A0',
          invitationTitle: '#4DC5C1'
        }
      }
  }
})

export default vuetify

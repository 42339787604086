
import * as types from '@/store/mutation-types'
// import { handleError } from '@/utils/utils.js'

const getters = {
  chosenTheme: state => state.chosenTheme
}

const actions = {
  setTheme({ commit }, payload) {
    return new Promise((resolve) => {
      commit(types.CHOSEN_THEME, payload.value)
      resolve(true)
    })
  }
}

const mutations = {
  [types.CHOSEN_THEME](state, value) {
    state.chosenTheme = value
  }
}

const state = {
  chosenTheme: null
}

export default {
  state,
  getters,
  actions,
  mutations
}

import * as types from '@/store/mutation-types'

const getters = {
  eventType: state => state.eventType,
  babyName: state => state.babyName,
  eventDate: state => state.eventDate,
  eventTime: state => state.eventTime,
  extraInfo: state => state.extraInfo,
}

const mutations = {
  [types.EVENT_TYPE](state, value) {
    state.eventType = value
  },
  [types.BABY_NAME](state, value) {
    state.babyName = value
  },
  [types.EVENT_DATE](state, value) {
    state.eventDate = value
  },
  [types.EVENT_TIME](state, value) {
    state.eventTime = value
  },
  [types.EXTRA_INFO](state, value) {
    state.extraInfo = value
  }
}

const state = {
  eventType: 'Chá de Bebê',
  babyName: null,
  eventDate: null,
  eventTime: null,
  extraInfo: null
}

export default {
  state,
  getters,
  mutations
}

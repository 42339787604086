export default [
  {
    path: '/address',
    name: 'address',
    meta: {
      title: 'Convite Chá de Bebê para Editar - Endereço da Festa'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/forms/Address.vue'),
  }
]

export default [
  {
    path: '/knownUs',
    name: 'known-us',
    meta: {
      title: 'Convite Chá de Bebê para Editar - Conheça a iFraldas'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/KnownUs.vue'),
  }
]

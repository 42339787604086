import api from '@/services/api/download'
import * as types from '@/store/mutation-types'

const getters = {
  base64: state => state.base64,
}

const actions = {

  downloadImg( { commit }, payload ){

    return new Promise((resolve, reject) => {
      api
        .downloadImg(payload)
        .then(response => {

          if (response.status === 200) {
            commit(types.BASE_64, response.data.image)
            resolve(true)
          }
        })
        .catch(error => {
          console.log(JSON.stringify(error,null,2))
          reject(false)
        })
    })
  }

}

const mutations = {
  [types.BASE_64](state, value) {
    state.base64 = value
  }
}

const state = {
  base64: null
}

export default {
  state,
  getters,
  actions,
  mutations
}

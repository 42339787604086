import Vue from 'vue'
import axios from 'axios'
// import { store } from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    // If request is different than any of the URLS in urlsExcludedForBearerHeader
    // then send Authorization header with token from localstorage
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {

    // erro diferente de 401
    if (error.response.status !== 401) {
      return Promise.reject(error)
    }

    console.log("ERRO STATUS:: "+error.response.status)
    console.log("ERRO URL:: "+error.config.url)

      // caso contrário apenas retorna o erro
      return new Promise((resolve, reject) => {
        reject(error);
      })

  }
  )


// eslint-disable-next-line no-shadow
Plugin.install = Vue => {
  Vue.axios = axios
  window.axios = axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      }
    },
    $axios: {
      get() {
        return axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin

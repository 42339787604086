import api from '@/services/api/lead'
import * as types from '@/store/mutation-types'

const getters = {
  slug: state => state.slug,
}

const actions = {

  postLead( { commit }, payload ){

    return new Promise((resolve, reject) => {
      api
        .postLead(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SLUG, response.data.slug)
            window.dataLayer.push({ event: 'generate_lead' })
            resolve(true)
          }
        })
        .catch(error => {
          console.log(JSON.stringify(error,null,2))
          reject(false)
        })
    })
  }

}

const mutations = {
  [types.SLUG](state, value) {
    state.slug = value
  }
}

const state = {
  slug: null
}

export default {
  state,
  getters,
  actions,
  mutations
}

export default [
  {
    path: '/themes',
    name: 'themes',
    meta: {
      title: 'Convite Chá de Bebê para Editar - Escolha o Tema'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/ChooseTheme.vue'),
  }
]

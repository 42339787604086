import api from '@/services/api/files'

import * as types from '@/store/mutation-types'

const getters = {
  invitationUploaded: state => state.invitationUploaded,
  carouselFinished: state => state.carouselFinished,
  uploadFinished: state => state.uploadFinished,
  uploadPercent: state => state.uploadPercent,
  files: state => state.files,
  invitationUrl: state => state.invitationUrl
}

const actions = {

  async getUploadUrl({ commit }, payload) {
    try {
      const response = await api.getXhrUrl(payload)
      commit(types.INVITATION_URL, `${payload.cdn}/${payload.bucket}/${payload.filename}`)
      return response.data.url
    } catch (error) {
      console.log('error', error)
    }
  },

  async newUploadFile({ commit }, params) {    
    
    return new Promise((resolve, reject) => {
      try {

        const xhr = new XMLHttpRequest()
        xhr.open(params.op, params.url)

        xhr.upload.onprogress = function(event) {            
            if (event.loaded) {                
                const percentage = (event.loaded / event.total) * 100
                commit(types.UPLOAD_PERCENT, percentage) 
            }
        }

        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
              commit(types.INVITATION_UPLOADED, true)
              commit(types.UPLOAD_PERCENT, 0)
              resolve(true)
            } else {
                console.log('Falha no upload', xhr.statusText)
                commit(types.INVITATION_UPLOADED, false)
                commit(types.UPLOAD_PERCENT, 0)
                reject(false)
            }
        }

        xhr.onerror = function() {
            console.error('Erro no upload:', xhr.statusText)
        }

        xhr.send(params.formData)
      } catch (error) {
        console.error('Erro no upload:', error)
      }
    })
  }

}

const mutations = {
  [types.INVITATION_UPLOADED](state, value) {
    state.invitationUploaded = value
  },
  [types.CAROUSEL_FINISHED](state, value) {
    state.carouselFinished = value
  },
  [types.UPLOAD_FINISHED](state, value) {
    state.uploadFinished = value
  },
  [types.UPLOAD_PERCENT](state, value) {
    state.uploadPercent = value
  },
  [types.FILES](state, value) {
    state.files = value
  },
  [types.INVITATION_URL](state, value) {
    state.invitationUrl = value
  }
}

const state = {
  invitationUploaded: false,
  carouselFinished: false,
  uploadFinished: false,
  uploadPercent: 0,
  files: '',
  invitationUrl: null
}

export default {
  state,
  getters,
  actions,
  mutations
}

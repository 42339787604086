// themes
export const CHOSEN_THEME = 'CHOSEN_THEME'
// invitation
export const EVENT_TYPE = 'EVENT_TYPE'
export const BABY_NAME = 'BABY_NAME'
export const EVENT_DATE = 'EVENT_DATE'
export const EVENT_TIME = 'EVENT_TIME'
export const EXTRA_INFO = 'EXTRA_INFO'
// Amazon S3
export const CAROUSEL_FINISHED = 'CAROUSEL_FINISHED'
export const UPLOAD_FINISHED = 'UPLOAD_FINISHED'
export const INVITATION_UPLOADED = 'INVITATION_UPLOADED'
export const UPLOAD_PERCENT = 'UPLOAD_PERCENT'
export const FILES = 'FILES'
export const INVITATION_URL = 'INVITATION_URL'
// Slug
export const SLUG = 'SLUG'
// Image Download
export const BASE_64 = 'BASE_64'

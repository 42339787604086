export default [
  {
    path: '/finish',
    name: 'finish',
    meta: {
      title: 'Convite Chá de Bebê para Editar - Compartilhar Convite'
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/GenerateInvitation.vue')
  }
]

export default [
  {
    path: '/name',
    name: 'name',
    meta: {
      title: 'Convite Chá de Bebê para Editar - Nome do Bebê'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/forms/Name.vue'),
  }
]

export default [
  {
    path: '/',
    name: 'landing',
    meta: {
      title: 'Convite Chá de Bebê para Editar - Tipo de evento'
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/EventType.vue')
  }
]
